import React from 'react'
import AnimatedCursor from 'react-animated-cursor'

function CostomCursor() {
    return (
        <div className='hidden lg:flex'>
            <AnimatedCursor
                innerSize={8}
                outerSize={55}
                innerScale={2}
                outerScale={1.5}
                outerAlpha={0.01}
                hasBlendMode={true}
                innerStyle={{
                    backgroundColor: 'var(--cursor-color)'
                }}
                outerStyle={{
                    border: '2px solid var(--cursor-circle-color)'
                }}
                clickables={[
                    'a',
                    'input[type="text"]',
                    'input[type="email"]',
                    'input[type="number"]',
                    'input[type="submit"]',
                    'input[type="file"]',
                    'input[type="image"]',
                    'label[for]',
                    'select',
                    'textarea',
                    'button',
                    '.link',
                    {
                        target: '.custom',
                        options: {
                            innerSize: 12,
                            outerSize: 12,
                            color: '255, 255, 255',
                            outerAlpha: 0.5,
                            innerScale: 0.7,
                            outerScale: 5
                        }
                    }
                ]}
            />
        </div>
    )
}

export default CostomCursor
import React from 'react'
import '../../App.css'
import InfintiMarquee from '../../Component/InfintiMarquee';

function WhatGain() {
    const items = [
        {
            h: 'Spiritual Enrichment',
            p: "You'll have the opportunity to strengthen your faith and deepen your spiritual connection to the teachings of Prophet Muhammad (ﷺ). The event's spiritual atmosphere, filled with the recitation of Mawlid, poetry, and nasheeds, will provide a profound sense of peace and devotion."
        },
        {
            h: 'Intellectual Growth',
            p: "The conference brings together distinguished scholars, grand muftis, and university leaders who will share their insights on the rich prophetic heritage. Engaging with these thought leaders will broaden your understanding of Islamic teachings and inspire intellectual growth."
        },
        {
            h: 'Cultural Unity',
            p: "You will witness and participate in a global gathering of individuals united by their love for the Prophet Muhammad (ﷺ). This event transcends cultural and geographical boundaries, offering a unique opportunity to connect with people from diverse backgrounds and foster a sense of global brotherhood."
        },
        {
            h: 'A Deep Sense of Community',
            p: "Being part of this sacred assembly allows you to experience the powerful sense of unity and love that binds participants. The conference is more than just an event; it’s a shared experience that forges lasting bonds among attendees."
        },
        {
            h: 'Personal Inspiration',
            p: "Through the speeches, activities, and spiritual ambiance, you will find inspiration to live a life more aligned with the virtues and character of the Prophet Muhammad (ﷺ). This conference will leave you with renewed motivation and a stronger sense of purpose."
        },
    ];
    return (
        <div className='relative w-full bg-gradient-to-t from-[#60c0ed5b] to-[#fff]'>
            <div className='relative'>
                <div className='absolute w-full -top-2 lg:-top-32'>
                    <InfintiMarquee />
                </div>
            </div>
            <div className='flex flex-col w-full gap-8 px-6 py-24 lg:pb-36 pt-36 lg:gap-16 lg:px-36'>
                <div className='flex flex-col gap-1 text-center'>
                    <h1 className='text-[#000] text-3xl tracking-tight leading-8 md:text-5xl font-extrabold uppercase'>what will you gain from conference</h1>
                </div>
                <div className='grid w-full h-auto grid-cols-1 gap-5'>
                    {items.map((item, index) => (
                        <div key={index} className="relative gap-5 p-6 px-7  md:p-14 shadow-xl rounded-3xl overflow-hidden xl:items-center justify-between xl:gap-20 bg-[#ffffff] text-[#242424] flex flex-col xl:flex-row ">
                            <h2 className='text-xl font-extrabold uppercase xl:w-1/3 lg:text-4xl xl:text-5xl'>{item.h}</h2>
                            <h2 className='lg:text-lg xl:w-2/3'>{item.p}</h2>
                        </div>
                    ))}
                </div>
            </div>
            <div className='z-40 absolute -bottom-10 w-full h-14 bg-[#fff] -rotate-3'></div>
            <div className='z-40 absolute -bottom-10 w-full h-14 bg-[#fff] rotate-3'></div>
        </div>
    )
}

export default WhatGain
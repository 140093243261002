import React, { useState, useEffect } from "react";
import { ref, listAll, getDownloadURL } from "firebase/storage";
import { storage } from "../utils/firebase";
import { MetaDecorator } from '../Component/MetaDecorator';
import hubburasool from '../assets/svg/hubburasool.svg'

function Gallary() {
  const [imageUrls, setImageUrls] = useState([]);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchImages = async () => {
      const listRef = ref(storage, "gallary/");
      try {
        const res = await listAll(listRef);
        const urls = await Promise.all(
          res.items.map((itemRef) => getDownloadURL(itemRef))
        );
        setImageUrls(urls);
      } catch (err) {
        setError("Failed to retrieve images");
        console.error("Error fetching images:", err);
      }
    };

    fetchImages();
  }, []);

  return (
    <div className='min-h-screen px-6 py-16 lg:py-20 lg:px-36'>
      <MetaDecorator
        title='Gallary - Hubbu Rasool Conference'
        description=' The Ernakulam Hubburasool Conference is a significant religious gathering that unites thousands of devotees in the spiritual heart of Kerala. This esteemed event, led by the respected Kalthara Usthad, focuses on spreading the message of love, peace, and unity through the teachings of Islam. The conference is renowned for its enlightening sessions and the presence of eminent scholars. A highlight of the event is the profound and honest speech delivered by Sheikh Abubakar Ahmed, whose words inspire and/.. gassetsde the attendees towards a path of righteousness and communal harmony. The conference not only serves as a platform for spiritual growth but also strengthens the bonds within the community, making it a pivotal event in the religious calendar.'
        imageUrl={hubburasool}
      />
      {error && <p>{error}</p>}
      {imageUrls.length > 0 ? (
        <div className='grid grid-cols-1 gap-3 md:grid-cols-2 lg:grid-cols-4 '>
          {imageUrls.map((url, index) => (
            <div key={index} className=" relative overflow-hidden h-72 group p-1 hover:p-0 transition-all duration-300 border-[#a1ddf9b3] border-8 rounded hover:rounded-[1.90rem] hover:scale-105">
              <img
                src={url}
                alt={`HUBBBURASOOL-${index}`}
                className="object-cover w-full h-full rounded "
              />
              <a
                href={url}
                target="_blank"
                rel="noopener noreferrer"
                download
                className="absolute text-white transition-opacity duration-300 ease-in-out opacity-0 right-3 bottom-3 rounded-3xl group-hover:opacity-100"
              >
                <button className="px-4 py-2 text-white bg-blue-500 rounded">
                  Download
                </button>
              </a>
            </div>
          ))}
        </div>
      ) : (
        <p>Loading images...</p>
      )}
    </div>
  )
}

export default Gallary
import React from 'react';

export function Modal({ isOpen, children }) {
    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white p-5 rounded-lg shadow-lg max-w-lg w-full">
                {children}
                
            </div>
        </div>
    );
}

import React from 'react';

export const CardStack = ({ SpeackerData }) => {

    return (
        <>
            {SpeackerData.map((card, index) => (
                <div key={index} className="relative flex items-end w-full overflow-hidden ">
                    <div className='w-full bg-[#a047ff] rounded-t-[400px] h-44 md:h-56 lg:h-64 mt-6 lg:mt-14'></div>
                    <img className={`z-10 w-full absolute right-0 bottom-0 h-full object-cover`} src={card.image} alt="" />
                </div>
            ))}
        </>
    );
};

import React, { useEffect, useState } from 'react';
import { ref as dbRef, onValue, update, remove } from "firebase/database";
import { db } from "../firebase";
import { Modal } from '../../Component/Modal'; // Import the Modal component

export function NewsList() {
    const [newses, setNewses] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedNews, setSelectedNews] = useState(null);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

    useEffect(() => {
        const newsRef = dbRef(db, "news");
        onValue(newsRef, (snapshot) => {
            const newsData = snapshot.val();
            const newsArray = [];
            for (let id in newsData) {
                newsArray.push({ id, ...newsData[id] });
            }
            newsArray.sort((a, b) => new Date(b.publishDate) - new Date(a.publishDate));
            setNewses(newsArray);
        });
    }, []);

    const openUpdateModal = (news) => {
        setSelectedNews(news);
        setIsModalOpen(true);
    };

    const handleUpdate = () => {
        const newsRef = dbRef(db, `news/${selectedNews.id}`);
        update(newsRef, { title: selectedNews.title, description: selectedNews.description, publishDate: selectedNews.publishDate }) // Update other fields as needed
            .then(() => {
                console.log("News updated successfully");
                setIsModalOpen(false);
            })
            .catch((error) => {
                console.error("Error updating news:", error);
            });
    };

    const openDeleteModal = (news) => {
        setSelectedNews(news);
        setIsDeleteModalOpen(true);
    };

    const handleDelete = () => {
        const newsRef = dbRef(db, `news/${selectedNews.id}`);
        remove(newsRef)
            .then(() => {
                console.log("News deleted successfully");
                setIsDeleteModalOpen(false);
            })
            .catch((error) => {
                console.error("Error deleting news:", error);
            });
    };

    const resetModal = () => {
        setSelectedNews(null);
        setIsModalOpen(false);
        setIsDeleteModalOpen(false);
    };

    return (
        <div className='flex flex-col w-full gap-5 py-5 font-manjari'>
            <h1 className="text-2xl font-bold lowercase text-start">News Update/Delete</h1>
            <div className="h-auto pb-5 overflow-auto max-h-96">
                <table className="min-w-full border divide-y rounded-lg shadow-md">
                    <thead>
                        <tr className="text-sm md:text-md">
                            <th scope="col" className="px-2 py-2 font-medium text-gray-500 uppercase md:px-6 text-start">NO:</th>
                            <th scope="col" className="px-2 py-2 font-medium text-gray-500 uppercase md:px-6 text-start">Title</th>
                            <th scope="col" className="px-2 py-2 font-medium text-gray-500 uppercase md:px-6 text-start">P Date</th>
                            <th scope="col" className="px-2 py-2 font-medium text-gray-500 uppercase md:px-6 text-end">Update</th>
                            <th scope="col" className="px-2 py-2 font-medium text-gray-500 uppercase md:px-6 text-end">Delete</th>
                        </tr>
                    </thead>
                    <tbody>
                        {newses.map((news, index) => (
                            <tr key={news.id} className="text-sm lg:text-base ">
                                <td className="px-2 py-1 font-medium text-gray-800 md:px-6 md:py-2 whitespace-nowrap">{index + 1}</td>
                                <td className="w-56 px-2 py-1 text-gray-800 md:text-lg md:px-6 md:py-2 whitespace-nowrap">{news.title}</td>
                                <td className="px-2 py-1 text-gray-800 md:px-6 md:py-2 whitespace-nowrap">{news.publishDate}</td>
                                <td className="px-2 py-1 font-medium md:px-6 md:py-2 whitespace-nowrap text-end">
                                    <button
                                        type="button"
                                        onClick={() => openUpdateModal(news)}
                                        className="inline-flex items-center font-semibold text-blue-600 border border-transparent rounded-lg gap-x-2 hover:text-blue-800"
                                    >
                                        Update
                                    </button>
                                </td>
                                <td className="px-2 py-1 font-medium md:px-6 md:py-2 whitespace-nowrap text-end">
                                    <button
                                        type="button"
                                        onClick={() => openDeleteModal(news)}
                                        className="inline-flex items-center font-semibold text-blue-600 border border-transparent rounded-lg gap-x-2 hover:text-blue-800"
                                    >
                                        Delete
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            {/* Update Modal */}
            <Modal isOpen={isModalOpen} onClose={resetModal}>
                <h2 className="text-xl font-semibold">Update News</h2>
                <div className='flex flex-col gap-2'>
                    <input
                        type="text"
                        value={selectedNews?.title || ''}
                        onChange={(e) => setSelectedNews({ ...selectedNews, title: e.target.value })}
                        className="w-full p-2 mt-3 border rounded"
                    />
                    <textarea
                        placeholder="Description"
                        className="p-2 border rounded-lg"
                        value={selectedNews?.description || ''}
                        onChange={(e) => setSelectedNews({ ...selectedNews, description: e.target.value })}
                    ></textarea>
                    <input
                        type="date"
                        value={selectedNews?.publishDate || ''}
                        onChange={(e) => setSelectedNews({ ...selectedNews, publishDate: e.target.value })}
                        className="p-2 border rounded-lg"
                    />
                </div>
                {/* Add other fields if needed */}
                <div className="flex justify-end gap-2 mt-4">
                    <button
                        className="px-4 py-2 text-gray-700 bg-gray-300 rounded-lg hover:bg-gray-400"
                        onClick={resetModal}
                    >
                        Cancel
                    </button>
                    <button
                        className="px-4 py-2 text-white bg-blue-500 rounded-lg hover:bg-blue-600"
                        onClick={handleUpdate}
                    >
                        Update
                    </button>
                </div>
            </Modal>

            {/* Delete Confirmation Modal */}
            <Modal isOpen={isDeleteModalOpen} onClose={resetModal}>
                <h2 className="text-xl font-semibold">Confirm Delete</h2>
                <p>Are you sure you want to delete this news item?</p>
                <div className="flex justify-end gap-2 mt-4">
                    <button
                        className="px-4 py-2 text-gray-700 bg-gray-300 rounded-lg hover:bg-gray-400"
                        onClick={resetModal}
                    >
                        Cancel
                    </button>
                    <button
                        className="px-4 py-2 text-white bg-red-500 rounded-lg hover:bg-red-600"
                        onClick={handleDelete}
                    >
                        Delete
                    </button>
                </div>
            </Modal>
        </div>
    );
}

export default NewsList;

import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Header from './Component/Header';
import Footer from './Component/Footer';
import CostomCursor from './Component/costomCursor';
import Home from './Pages/HomeSection/Home';
import About from './Pages/About';
import News from './Pages/News';
import Gallary from './Pages/Gallary';
import CurrentNews from './Pages/CurrentNews';
import Downloads from './Pages/Downloads';
import Admin from './utils/admin/Admin';

function App() {
  return (
    <BrowserRouter>
      <CostomCursor />
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/About" element={<About />} />
        <Route path="/News" element={<News />} />
        <Route path="/News/:id" element={<CurrentNews />} />
        <Route path="/admin313" element={<Admin />} />
        <Route path="/Gallary" element={<Gallary />} />
        <Route path="/Downloads" element={<Downloads />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;

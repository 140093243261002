import React, { useState } from "react";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage } from "../firebase"; // Import storage from your firebase.js
import imageCompression from "browser-image-compression";

const FileUpload = () => {
    const [compressedFile, setCompressedFile] = useState(null);
    const [progress, setProgress] = useState(0);
    const [fileSize, setFileSize] = useState(0);
    const [compressedSize, setCompressedSize] = useState(0);
    const [isUploading, setIsUploading] = useState(false);

    const handleFileChange = async (e) => {
        const originalFile = e.target.files[0];
        if (!originalFile) return;

        // Set the original file size
        setFileSize((originalFile.size / 1024 / 1024).toFixed(2)); // Size in MB

        // Compress the image
        const options = {
            maxSizeMB: 1, // Maximum file size in MB
            maxWidthOrHeight: 1920, // Maximum width or height of the output image
            useWebWorker: true,
        };

        try {
            const compressedImage = await imageCompression(originalFile, options);
            setCompressedFile(compressedImage);

            // Set the compressed file size
            setCompressedSize((compressedImage.size / 1024 / 1024).toFixed(2)); // Size in MB
        } catch (error) {
            console.error("Error compressing the image:", error);
        }
    };

    const handleUpload = () => {
        if (!compressedFile) return;

        setIsUploading(true); // Indicate that upload is in progress

        const storageRef = ref(storage, `gallary/${compressedFile.name}`);
        const uploadTask = uploadBytesResumable(storageRef, compressedFile);

        uploadTask.on(
            "state_changed",
            (snapshot) => {
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                setProgress(progress);
            },
            (error) => {
                console.error("Upload failed:", error);
                setIsUploading(false); // Reset if upload fails
            },
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    setIsUploading(false); // Reset after successful upload
                });
            }
        );
    };



    return (
        <div className="flex flex-col gap-5">
            <h1 className="text-2xl font-bold lowercase text-start">Admin/Gallary</h1>
            <div className="w-full h-32 md:p-20 bg-[#00000027] rounded-xl p-5 items-center flex justify-center">
                <input type="file" className="object-cover w-44 md:w-fit" onChange={handleFileChange} />
            </div>
            <ol className="flex flex-col gap-3 md:items-center md:justify-between lg:text-lg md:gap-10 md:flex-row">
                <li>Original File Size: {fileSize} MB</li>
                {compressedSize > 0 && <li>Compressed File Size: {compressedSize} MB</li>}
                <button
                    onClick={handleUpload}
                    className={`p-1.5 w-full md:w-fit px-5 text-white capitalize bg-blue-500 lg:text-lg  rounded-xl transition-opacity ${isUploading ? "opacity-50 cursor-not-allowed" : ""
                        }`}
                    disabled={isUploading || !compressedFile}
                >
                    {isUploading ? `Uploading... ${Math.round(progress)}%` : "Upload Photos"}
                </button>
            </ol>
        </div>
    );
};

export default FileUpload;

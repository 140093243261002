import React from 'react'
import { Helmet } from 'react-helmet'
import { useLocation } from 'react-router-dom'

export const MetaDecorator = ({ title, description, imageUrl, location, type }) => {
    const Currentlocation = useLocation()

    return (
        <Helmet>
            {/* Primary Meta Tags */}
            <meta charset="UTF-8" />
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            <title>{title}</title>
            <meta name="title" content={title} />
            <meta name="description" content={description} />

            {/* Open Graph / Facebook */}
            <meta property="og:type" content={type ? type : 'website'} />
            <meta property="og:url" content={location ? location : Currentlocation} />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta property="og:image" content={imageUrl} />

            {/* Twitter */}
            <meta name="twitter:card" content="summary_large_image" />
            <meta property="twitter:url" content={location} />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={description} />
            <meta name="twitter:image" content={imageUrl} />
        </Helmet>
    )
}
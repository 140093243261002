// import { motion } from 'framer-motion';
import React, { useState } from 'react'
import { CgClose, CgMenu } from 'react-icons/cg'
import { Link, useLocation } from 'react-router-dom';

function Header() {
    const [navOpen, setNavOpen] = useState(false);
    const location = useLocation()

    const navigations = [
        { option: 'Home', path: '/' },
        { option: 'About', path: '/About' },
        { option: 'News', path: '/News' },
        { option: 'Gallary', path: '/Gallary' },
        { option: 'Downloads', path: '/Downloads' },
    ];

    // const dropIn = {
    //     hidden: {
    //         x: "100vh",
    //         opacity: 0,
    //     },
    //     visible: {
    //         x: "0",
    //         opacity: 1,
    //         transition: {
    //             duration: 0.1,
    //             type: "spring",
    //             damping: 20,
    //             stiffness: 500,
    //         },
    //     },
    //     exit: {
    //         x: "100vh",
    //         opacity: 0,
    //     },
    // };

    return (
        <>
            {!navOpen &&
                <div className={` ${location.pathname === '/' ? 'bg-transparent absolute text-[#ffffffcb]  py-10' : 'bg-[#ac5dff] py-7 text-white'} top-0 right-0  z-50 flex items-center justify-center w-full h-auto px-6  lg:px-36`}>
                    <div className='items-center justify-between hidden w-full md:flex'>
                        <Link to='/'>
                            <h3 className={` font-semibold text-3xl`}>Hubbu Rasool</h3>
                        </Link>
                        <ul className={` tracking-wider font-normal items-center hidden gap-6 text-lg capitalize md:flex`}>
                            {navigations.map((nav) => (
                                <Link to={nav.path}>
                                    <li>{nav.option}</li>
                                </Link>
                            ))}
                        </ul>
                    </div>
                    <div className={`flex items-center justify-between w-full md:hidden`}>
                        <h3 className={`${location.pathname === '/' ? 'font-normal ' : 'font-bold'} text-xl`}>Hubbu Rasool</h3>
                        <span>
                            <CgMenu onClick={() => setNavOpen(true)} className='text-2xl font-normal ' />
                        </span>
                    </div>
                </div>
            }
            {navOpen &&
                <div
                    // onClick={(e) => e.stopPropagation()}
                    // variants={dropIn}
                    // initial="hidden"
                    // animate="visible"
                    // exit="exit"
                    className={`md:hidden w-full h-screen pr-16 bg-transparent flex-col justify-start items-start z-50 right-0 top-0 fixed`}>
                    <div className='relative p-10 bg-[#a1ddf9] h-full '>
                        <div className="absolute flex items-center justify-center h-auto top-10 -right-12 ">
                            <CgClose
                                className={`text-4xl bg-[#9e48f9] text-white rounded-full p-1.5 font-bold  cursor-pointer`}
                                onClick={() => setNavOpen(false)}
                            />
                        </div>
                        <Link to='/'>
                            <h3 className={`text-[#000] font-semibold text-2xl`}>Hubbu Rasool</h3>
                        </Link>
                        <ul className={`flex flex-col  py-10 w-full h-full transition-all duration-1000`}>
                            {navigations.map((navigation, index) => (
                                <Link to={navigation.path}>
                                    <li
                                        onClick={() => setNavOpen(false)}
                                        key={index}
                                        className={`font-semibold text-black hover:bg-[#9e48f9] w-full py-4 border-[#00000078] hover:px-6 hover:text-white hover:rounded-md uppercase `}
                                    >
                                        <span
                                            onClick={() => { window.scrollTo({ top: 0, left: 0, behavior: 'smooth' }); }}
                                            className="cursor-pointer"
                                        >
                                            {navigation.option}
                                        </span>
                                    </li>
                                </Link>
                            ))}
                        </ul>
                    </div>
                </div>
            }
        </>
    )
}

export default Header
import React from 'react'
import FileUpload from './Fileupload'
import NewsUpload from './NewsUplaod'
import NewsList from './NewsList'

function Admin() {
    return (
        <div className='w-full min-h-screen px-6 py-10 md:py-20 lg:px-36'>
            <FileUpload />
            <hr className='my-10' />
            <NewsUpload />
            <hr className='my-10' />
            <NewsList/>
        </div>
    )
}

export default Admin
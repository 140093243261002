import React from 'react'
import image2 from '../../assets/images/speakers/sheikhuna.png'
import bg2 from '../../assets/images/bg2.jpg'
import { Link } from 'react-router-dom';
import { SpeackersData } from '../../Data/SpeackersData';
import { CardStack } from '../../Component/card-stack';
import { MdArrowOutward } from 'react-icons/md';

function Speackers() {

    return (
        <div className='flex flex-col items-center justify-center w-full gap-10 px-6 py-16 lg:py-20 lg:gap-16 lg:px-36'>
            <h1 className='text-[#000] text-3xl leading-8 md:text-5xl font-extrabold text-center uppercase '>Hubbu Rasool Speakers</h1>
            <div style={{ backgroundImage: `url(${bg2})` }} className='relative pb-72 md:pb-10 bg-cover bg-gradient-to-t from-black bg-no-repeat overflow-hidden w-full p-6 lg:p-16 lg:px-20 rounded-3xl lg:rounded-[4rem]'>
                <div className='flex flex-col gap-5 text-white md:w-1/2 lg:w-2/3 md:gap-10'>
                    <h2 className='text-3xl font-bold leading-8 uppercase xl:text-5xl'> <span className='text-[#60c0ed]'>main attraction is</span> Madh Rasool speach by<span className='text-[#60c0ed]'> sheikh abubakr ahmed </span></h2>
                    <Link to='https://sheikhabubakrahmad.com/' className='flex items-center gap-3 p-1.5 pl-6 text-lg text-white bg-[#ac5dff] rounded-full lg:text-2xl w-fit' >
                        <span>Grand Mufti, India</span>
                        <MdArrowOutward className='p-1 text-3xl text-[#ac5dff] bg-white rounded-full md:text-4xl' />
                    </Link>
                </div>
                <img className='absolute -bottom-5 lg:-bottom-16 xl:top-1 w-[350px] md:w-96 xl:w-[500px] right-0' src={image2} alt="" />
            </div>
            <div className='grid w-full h-full grid-cols-2 gap-5 xl:gap-10 md:grid-cols-4 xl:grid-cols-4'>
                <CardStack SpeackerData={SpeackersData} />
            </div>
        </div>
    )
}

export default Speackers

import React from 'react';
import image from '../../assets/images/gallary/1.png';
import { useCountUp } from 'react-countup';
import earth from '../../assets/svg/earth.svg';
import speech from '../../assets/svg/speech.svg';
import book from '../../assets/svg/book.svg';
import users from '../../assets/svg/users.svg';
import sparkles from '../../assets/svg/sparkles.svg';
import food from '../../assets/svg/food.svg';
import { useTimer } from 'react-timer-hook';
import { StatusItem } from '../../Component/Status';

function WorldLargest() {
    const countUpOptions = { duration: 10 };
    useCountUp({ ref: '1', end: 33, ...countUpOptions });
    useCountUp({ ref: '2', end: 11, ...countUpOptions });
    useCountUp({ ref: '3', end: 99, ...countUpOptions });
    useCountUp({ ref: '4', end: 99, ...countUpOptions });
    useCountUp({ ref: '5', end: 3, ...countUpOptions });
    useCountUp({ ref: '6', end: 99, ...countUpOptions });

    const expiryTimestamp = new Date('2024-09-10T00:00:00').getTime();
    // const {
    //     seconds,
    //     minutes,
    //     hours,
    //     days,
    // } = useTimer({ expiryTimestamp, onExpire: () => console.log('Expired!') });

    // const formatNumber = (number) => String(number).padStart(2, '0');

    return (
        <div className='flex flex-col w-full h-full gap-12 lg:gap-16 lg:pb-20'>
            <div className='relative bg-[#f0eae7]'>
                <div className='flex flex-col items-center justify-center w-full gap-5 px-6 py-10 pb-16 text-center lg:px-36 lg:py-20 md:gap-8'>
                    <h1 className='text-[#050404] text-3xl tracking-tight md:text-5xl font-bold lg:font-extrabold uppercase'>
                        The World's Largest <br /> Spiritual Gathering
                    </h1>
                    <h2 className='font-normal leading-5 text-md md:text-xl'>
                        The Ernakulam Hubburasool Conference is a significant religious gathering that unites thousands of devotees in the spiritual heart of Kerala. This esteemed event, led by the respected Kalthara Usthad, focuses on spreading the message of love, peace, and unity through the teachings of Islam. The conference is renowned for its enlightening sessions and the presence of eminent scholars. A highlight of the event is the profound and honest speech delivered by Sheikh Abubakar Ahmed, whose words inspire and/.. gassetsde the attendees towards a path of righteousness and communal harmony. The conference not only serves as a platform for spiritual growth but also strengthens the bonds within the community, making it a pivotal event in the religious calendar.
                    </h2>
                    {/* <div className='relative mt-10 md:my-8'>
                        <div className='absolute inset-0 flex items-start justify-center w-full text-lg font-semibold -top-4 lg:-top-2 -rotate-3 md:-rotate-6'>
                            <h2 className='bg-[#ac5dff] text-white p-0.5 px-3 text-xs md:text-lg rounded w-fit uppercase'>Schedule</h2>
                        </div>
                        <div className='grid items-center text-[3rem] leading-10 font-semibold md:text-8xl lg:text-9xl grid-cols-1 text-center w-fit'>
                            <div className='flex w-fit'>
                                <h1>{formatNumber(days)}:{formatNumber(hours)}</h1>
                                <h1>:{formatNumber(minutes)}:{formatNumber(seconds)}</h1>
                            </div>
                            <div className='grid w-full grid-cols-4 text-[0.7rem] leading-5 md:text-sm font-normal uppercase lg:text-lg text-start'>
                                <h1 className='xl:px-5'>Days</h1>
                                <h1>Hours</h1>
                                <h1 className='xl:px-2'>Mins</h1>
                                <h1 className='xl:px-2'>Secs</h1>
                            </div>
                        </div>
                    </div> */}
                    <div className='z-40 absolute bottom-0 w-full h-10 bg-[#f0eae7] -rotate-3'></div>
                    <div className='z-40 absolute bottom-0 w-full h-10 bg-[#f0eae7] rotate-3'></div>
                </div>
            </div>
            <div className='overflow-hidden w-full relative bg-gradient-to-t from-[#60c0ed7a] to-[#fff]'>
                <div className='px-6 lg:px-36 flex flex-col lg:pt-20 pb-44 lg:pb-[550px] w-full h-auto gap-8 lg:gap-16 text-center bg-center'>
                    <h1 className='text-3xl font-bold tracking-tight text-black uppercase md:font-extrabold lg:text-5xl'>Hubbu Rasool in Numbers</h1>
                    <div className='z-10 w-full h-full grid-cols-1 grid md:grid-cols-2 xl:grid-cols-3 gap-5 text-[#221d22] items-center justify-center'>
                        <StatusItem count={<span id='1' />} icon={speech} measurement='+' name='Speakers' />
                        <StatusItem count={<span id='2' />} icon={earth} measurement='+' name='Nationalities' />
                        <StatusItem count={<span id='3' />} icon={book} measurement='+' name='Moulid Reciters' />
                        <StatusItem count={<span id='4' />} icon={users} measurement='K+' name='Attendees' />
                        <StatusItem count={<span id='5' />} icon={sparkles} measurement='K+' name='Volunteers' />
                        <StatusItem count={<span id='6' />} icon={food} measurement='K+' name='Food Serving' />
                    </div>
                </div>
                <img src={image} className='absolute w-full scale-150 bottom-5 right-8 md:right-0 md:scale-100 md:-bottom-20' alt="Event Image" />
            </div>
        </div>
    );
}

export default WorldLargest;

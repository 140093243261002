import React, { useState } from "react";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { ref as dbRef, push } from "firebase/database";
import { db, storage } from "../firebase";

const NewsUpload = () => {
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [image, setImage] = useState(null);
    const [publishDate, setPublishDate] = useState("");
    const [progress, setProgress] = useState(0);
    const [isUploading, setIsUploading] = useState(false);

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        setImage(file);
    };

    const handleUpload = async () => {
        if (!title || !description || !image || !publishDate) {
            alert("All fields are required!");
            return;
        }

        setIsUploading(true);
        const word = title.split(" ");
        const fisrtTwoWords = word.slice(0, 2).join(" ")

        // Upload image to Firebase Storage
        const storageRef = ref(storage, `newsImages/${fisrtTwoWords + publishDate}`);
        const uploadTask = uploadBytesResumable(storageRef, image);

        uploadTask.on(
            "state_changed",
            (snapshot) => {
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                setProgress(progress);
            },
            (error) => {
                console.error("Image upload failed:", error);
                setIsUploading(false);
            },
            async () => {
                const imageUrl = await getDownloadURL(uploadTask.snapshot.ref);
                await saveNewsData(imageUrl);
                setIsUploading(false);
                resetForm();
            }
        );
    };

    const saveNewsData = async (imageUrl) => {
        try {
            const newsData = {
                title,
                description,
                imageUrl,
                publishDate,
                createdAt: new Date().toISOString(),
            };
            const newsRef = dbRef(db, "news");
            await push(newsRef, newsData);
        } catch (error) {
            console.error("Error uploading news:", error);
        }
    };

    const resetForm = () => {
        setTitle("");
        setDescription("");
        setImage(null);
        setPublishDate("");
        setProgress(0);
    };

    return (
        <div className="flex flex-col gap-6 md:py-5">
            <h1 className="text-2xl font-bold lowercase text-start">Admin/Upload News</h1>
            <div className="grid grid-cols-1 gap-5 text-start md:grid-cols-2">
                <input
                    type="text"
                    placeholder="Title"
                    className="p-2 border rounded-lg"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                />
                <textarea
                    placeholder="Description"
                    required
                    className="p-2 border rounded-lg"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                ></textarea>
                <input
                    type="file"
                    required
                    onChange={handleImageChange}
                    className="p-2 border rounded-lg"
                />

                <input
                    type="date"
                    required
                    className="p-2 border rounded-lg"
                    value={publishDate}
                    onChange={(e) => setPublishDate(e.target.value)}
                />
            </div>
            <button
                onClick={handleUpload}
                className={`p-2 text-white rounded-lg transition-colors ${isUploading
                    ? "bg-gray-500 cursor-not-allowed"
                    : "bg-blue-500 hover:bg-blue-700"
                    }`}
                disabled={isUploading}
            >
                {isUploading ? `Uploading... ${Math.round(progress)}%` : "Upload News"}
            </button>
        </div>
    );
};

export default NewsUpload;

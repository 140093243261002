import React from 'react';
import hubburasool from '../../assets/svg/hubburasool.svg'
import HRvideo from '../../assets/hubburasool.mp4';
import '../../App.css';
import ReachUs from './ReachUs';
import Speackers from './Speackers';
import WorldLargest from './WorldLargest';
import WhatGain from './WhatGain';
import { MetaDecorator } from '../../Component/MetaDecorator';

function Home() {

    return (
        <div className='relative w-full h-full bg-[#ffffffee]'>
            <MetaDecorator
                title='Hubbu Rasool Conference'
                description=' The Ernakulam Hubburasool Conference is a significant religious gathering that unites thousands of devotees in the spiritual heart of Kerala. This esteemed event, led by the respected Kalthara Usthad, focuses on spreading the message of love, peace, and unity through the teachings of Islam. The conference is renowned for its enlightening sessions and the presence of eminent scholars. A highlight of the event is the profound and honest speech delivered by Sheikh Abubakar Ahmed, whose words inspire and/.. gassetsde the attendees towards a path of righteousness and communal harmony. The conference not only serves as a platform for spiritual growth but also strengthens the bonds within the community, making it a pivotal event in the religious calendar.'
                imageUrl={hubburasool}
            />
            <div className='relative w-full h-screen overflow-hidden text-center bg-black select-none'>
                <div className='absolute z-10 w-full h-full bg-black opacity-70'></div>
                <video
                    src={HRvideo}
                    autoPlay
                    loop
                    muted
                    className={`object-cover w-full h-full bg-center `}
                ></video>
                <div className='absolute inset-0 z-10 flex flex-col items-center justify-center w-full h-auto gap-5 mt-24'>
                    <img src={hubburasool} alt="" className='w-[260px] h-[130px] overflow-hidden md:h-[150px]  md:w-[400px] lg:w-[450px] lg:h-[220px] xl:h-[280px] xl:w-[500px]' />
                </div>
            </div>
            <WorldLargest />
            <WhatGain />
            <Speackers />
            <ReachUs />
        </div>
    );
}

export default Home;

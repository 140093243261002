import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getDatabase } from "firebase/database";

const firebaseConfig = {
    apiKey: "AIzaSyC4EuEvo-UZhD339kR0rBtuRU_K3uq957I",
    authDomain: "hubburasool-131af.firebaseapp.com",
    projectId: "hubburasool-131af",
    storageBucket: "hubburasool-131af.appspot.com",
    messagingSenderId: "554593545322",
    appId: "1:554593545322:web:04aaf46d3c998c70706e13",
    measurementId: "G-20NJE5PLNG"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const storage = getStorage(app)
const db = getDatabase(app)

export { db, storage }
import React, { useEffect, useState, useCallback } from "react";
import { ref as dbRef, onValue } from "firebase/database";
import { db } from "../utils/firebase";
import { Link, useParams } from "react-router-dom";
import {
    FacebookShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    FacebookIcon,
    TwitterIcon,
    WhatsappIcon,
} from "react-share";
import { IoCopyOutline } from "react-icons/io5";
import { MetaDecorator } from '../Component/MetaDecorator';
import { useLocation } from 'react-router-dom'
import { MdArrowOutward } from "react-icons/md";

export default function CurrentNews() {
    const { id } = useParams();
    const [currentNews, setCurrentNews] = useState(null);
    const [relatedPosts, setRelatedPosts] = useState([]);

    const location = useLocation();

    const fetchRelatedPosts = useCallback((category) => {
        const relatedPostsRef = dbRef(db, "news");

        onValue(relatedPostsRef, (snapshot) => {
            const allNews = snapshot.val();
            const filteredPosts = Object.entries(allNews)
                .filter(
                    ([key, newsItem]) => newsItem.category === category && key !== id
                )
                .map(([key, newsItem]) => ({ id: key, ...newsItem }));

            setRelatedPosts(filteredPosts);
        });
    }, [id]);

    useEffect(() => {
        const newsRef = dbRef(db, `news/${id}`);

        onValue(newsRef, (snapshot) => {
            const newsData = snapshot.val();
            if (newsData) {
                setCurrentNews(newsData);
                fetchRelatedPosts(newsData.category);
            } else {
                console.error("News item not found");
            }
        });
    }, [id, fetchRelatedPosts]);

    const copyToClipboard = () => {
        navigator.clipboard.writeText(location.href);
        alert("URL copied to clipboard!");
    };

    return (
        <div className="flex flex-col min-h-screen gap-5 px-6 py-14 lg:py-20 lg:px-36 ">
            <MetaDecorator
                type='article'
                title={currentNews?.title}
                description={currentNews?.description}
                imageUrl={currentNews?.imageUrl}
                location={location.href}
            />
            {currentNews ? (
                <div className="flex flex-col items-start justify-center w-full h-full gap-5 font-manjari md:items-center md:text-center ">
                    <h2 className="text-4xl font-medium capitalize md:text-4xl xl:text-6xl">
                        {currentNews.title}
                    </h2>

                    {/* Share Buttons */}
                    <div className="flex gap-3 text-xl ">
                        <button
                            onClick={copyToClipboard}
                            className="p-2.5 text-white bg-blue-600 rounded-full hover:bg-blue-600"
                        >
                            <IoCopyOutline />
                        </button>
                        <FacebookShareButton url={location.href}>
                            <FacebookIcon size={40} round={true} />
                        </FacebookShareButton>
                        <TwitterShareButton url={location.href}>
                            <TwitterIcon size={40} round={true} />
                        </TwitterShareButton>
                        <WhatsappShareButton url={location.href}>
                            <WhatsappIcon size={40} round={true} />
                        </WhatsappShareButton>
                    </div>

                    {/* Main News Content */}
                    <img
                        src={currentNews.imageUrl}
                        alt={currentNews.title}
                        className="object-cover w-full rounded-xl md:mt-5 h-72 md:h-full"
                    />

                    <div className="w-full text-xl md:text-2xl">
                        {currentNews.description}
                    </div>

                    {/* Related Posts */}
                    {relatedPosts.length > 0 && (
                        <div className="mt-12 space-y-5 lg:space-y-10 md:mt-20">
                            <h3 className="text-3xl font-semibold">Related Posts</h3>
                            <div className="grid grid-cols-1 gap-5 xl:gap-10 md:grid-cols-2 lg:grid-cols-3">
                                {relatedPosts.slice(0, 4).map((news) => (
                                    <div className="flex flex-col items-start justify-between h-full gap-3 overflow-hidden font-manjari">
                                        <img
                                            src={news.imageUrl}
                                            alt={news.title}
                                            className="object-cover w-full h-56 rounded-t-3xl md:h-64"
                                        />
                                        <h2 className="text-2xl font-normal leading-7 md:text-3xl ">{news.title}</h2>
                                        <Link className='flex items-center gap-3 p-1 pl-4 text-white bg-black rounded-full md:text-lg' to={`/News/${news.id}`} onClick={() => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })} >
                                            <span className="pt-1">Continue Read</span>
                                            <MdArrowOutward className='p-1 text-2xl text-black bg-white rounded-full md:text-3xl' />
                                        </Link>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                </div>
            ) : (
                <p>Loading News...</p>
            )}
        </div>
    );
}

import React from 'react'

function ReachUs() {
    const Panel = [
        { tabKey: 'venue', title: 'Javaharlal Nehru Stadium, Ernakulam, Kerala, India', MapLink: 'https://maps.app.goo.gl/4C43mC9U9eRMyFHd6' },
        { tabKey: 'Data_time', title: <span>10th September 2024, <br /> Ernakulam, Kerala, India</span>, SubTitle: '4.00 PM (IST)', },
        { tabKey: 'contact', title: '+919072500442', SubTitle: <span>hubburasool@gmail.com <br /> mamaululoom@gmail.com</span>, },
    ]
    return (
        <div className='flex bg-gradient-to-t from-[#f0eae7] flex-col w-full  px-6 py-10 text-center lg:px-36 lg:pb-20'>
            <h1 className='text-[#000] text-3xl tracking-tight leading-8 md:text-5xl font-extrabold uppercase'>get direction</h1>
            <iframe
                className='w-full mt-8 md:mt-16 h-96 xl:h-[350px] rounded-3xl'
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3929.2135866866433!2d76.2987968653844!3d9.999208400535533!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b080d166d3a98e3%3A0xc8b2f4d8eae5cacd!2sJawaharlal%20Nehru%20International%20Stadium%20Kochi!5e0!3m2!1sen!2sin!4v1724129870987!5m2!1sen!2sin"
                style={{ border: 1 }}
                allowFullScreen
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            />
            <div className='grid w-full grid-cols-1 mt-8 text-black text-start md:text-center xl:grid-cols-3 gap-7 xl:py-5 rounded-3xl'>
                {Panel.map((tab) => (
                    <div key={tab.tabKey} className='flex flex-col gap-1 '>
                        <h2 className="text-lg font-bold capitalize md:text-xl">{tab.title}</h2>
                        <h3 className="text-md md:text-lg text-[#000000bf] capitalize ">{tab?.SubTitle}</h3>
                        {tab?.MapLink && (
                            <a href={tab.MapLink} className="block text-[#7777ff] underline capitalize md:text-lg" target="_blank" rel="noopener noreferrer">
                                Get Direction on Map
                            </a>
                        )}
                    </div>
                ))}
            </div>
        </div>
    )
}

export default ReachUs;

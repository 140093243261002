import React from 'react'
import { FaFacebook, FaInstagram, FaTwitter, FaWhatsapp } from 'react-icons/fa'
import '../App.css'
import { Link } from 'react-router-dom';

function Footer() {
    const navigations = [
        { option: 'Home', path: '/' },
        { option: 'About', path: '/about' },
        { option: 'News', path: '/News' },
        { option: 'Gallary', path: '/Gallary' },
        { option: 'Downloads', path: '/downloads' },
    ];
    return (
        <div className='bg-black  px-6 lg:px-36 flex flex-col gap-10 md:gap-20  text-[#ffffffc5] py-12 md:py-20'>
            <div className='flex flex-col items-start w-full md:items-center md:flex-row gap-7 text-start md:justify-between'>
                <div className='flex flex-col gap-5 md:gap-7'>
                    <h1 className='text-lg font-semibold text-white uppercase md:text-xl'>contact us</h1>
                    <ul className='flex flex-col gap-1 capitalize md:gap-3 md:text-xl '>
                        <li>hubburasool786@gmail.com</li>
                        <li>+971 42941600</li>
                        <li className='flex items-center gap-2 pt-2 text-2xl md:text-3xl'>
                            <FaWhatsapp />
                            <FaTwitter />
                            <FaInstagram />
                            <FaFacebook />
                        </li>
                    </ul>
                </div>
                <div className='flex flex-col gap-5 md:gap-7'>
                    <h1 className='text-lg font-semibold text-white uppercase md:text-xl'>quick links</h1>
                    <ul className='flex flex-col gap-2 text-sm uppercase md:text-base '>
                        {navigations.map((nav, index) => (
                            <Link key={index} to={nav.path}>
                                <li>{nav.option}</li>
                            </Link>
                        ))}
                    </ul>
                </div>
                <div className='flex flex-col gap-5 md:gap-7'>
                    <h1 className='text-lg font-semibold text-white uppercase md:text-xl'>Hubbu Rasool</h1>
                    <ul className='flex flex-col gap-2 text-sm uppercase md:text-base '>
                        {navigations.map((nav, index) => (
                            <Link key={index} to={nav.path}>
                                <li>{nav.option}</li>
                            </Link>
                        ))}
                    </ul>
                </div>
            </div>
            <div className='flex flex-col justify-between w-full gap-3 md:flex-row md:items-center'>
                <h1 className='text-4xl font-semibold'> Hubbu Rasool</h1>
                <p>Copyright © 2024 Hubbu Rasool. All Rights Reserved.</p>
            </div>
        </div>
    )
}

export default Footer
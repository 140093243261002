import React from 'react'
import { MetaDecorator } from '../Component/MetaDecorator';
import hubburasool from '../assets/svg/hubburasool.svg'


function Downloads() {
  return (
    <div className='flex items-center justify-center min-h-screen px-6 lg:px-36'>
      <MetaDecorator
        title='Downloads - Hubbu Rasool Conference'
        description=' The Ernakulam Hubburasool Conference is a significant religious gathering that unites thousands of devotees in the spiritual heart of Kerala. This esteemed event, led by the respected Kalthara Usthad, focuses on spreading the message of love, peace, and unity through the teachings of Islam. The conference is renowned for its enlightening sessions and the presence of eminent scholars. A highlight of the event is the profound and honest speech delivered by Sheikh Abubakar Ahmed, whose words inspire and/.. gassetsde the attendees towards a path of righteousness and communal harmony. The conference not only serves as a platform for spiritual growth but also strengthens the bonds within the community, making it a pivotal event in the religious calendar.'
        imageUrl={hubburasool}
      />
      <p>Nothing to download now.</p>
    </div>
  )
}

export default Downloads
import React from 'react'
import '../App.css'

export function InfintiMarquee() {

    return (
        <div className='flex flex-col bg-[#000] py-3 lg:py-7 gap-1 lg:gap-4 text-2xl lg:text-4xl -rotate-1'>
            <div class="marqueeL  font-semibold ">
                <div class="marqueeL__group">
                    <div className='flex items-center gap-5'>
                        <span>10th september </span>
                        <span className='w-4 h-4 lg:w-7 lg:h-7 bg-[#ac5dff] rounded-full'></span>
                    </div>
                    <div className='flex items-center gap-5'>
                        <span>10th september </span>
                        <span className='w-4 h-4 lg:w-7 lg:h-7 bg-[#ac5dff] rounded-full'></span>
                    </div>
                    <div className='flex items-center gap-5'>
                        <span>10th september </span>
                        <span className='w-4 h-4 lg:w-7 lg:h-7 bg-[#ac5dff] rounded-full'></span>
                    </div>
                    <div className='flex items-center gap-5'>
                        <span>10th september </span>
                        <span className='w-4 h-4 lg:w-7 lg:h-7 bg-[#ac5dff] rounded-full'></span>
                    </div>
                    <div className='flex items-center gap-5'>
                        <span>10th september </span>
                        <span className='w-4 h-4 lg:w-7 lg:h-7 bg-[#ac5dff] rounded-full'></span>
                    </div>
                    <div className='flex items-center gap-5'>
                        <span>10th september </span>
                        <span className='w-4 h-4 lg:w-7 lg:h-7 bg-[#ac5dff] rounded-full'></span>
                    </div>
                    <div className='flex items-center gap-5'>
                        <span>10th september </span>
                        <span className='w-4 h-4 lg:w-7 lg:h-7 bg-[#ac5dff] rounded-full'></span>
                    </div>
                    <div className='flex items-center gap-5'>
                        <span>10th september </span>
                        <span className='w-4 h-4 lg:w-7 lg:h-7 bg-[#ac5dff] rounded-full'></span>
                    </div>
                    <div className='flex items-center gap-5'>
                        <span>10th september </span>
                        <span className='w-4 h-4 lg:w-7 lg:h-7 bg-[#ac5dff] rounded-full'></span>
                    </div>
                    <div className='flex items-center gap-5'>
                        <span>10th september </span>
                        <span className='w-4 h-4 lg:w-7 lg:h-7 bg-[#ac5dff] rounded-full'></span>
                    </div>
                    <div className='flex items-center gap-5'>
                        <span>10th september </span>
                        <span className='w-4 h-4 lg:w-7 lg:h-7 bg-[#ac5dff] rounded-full'></span>
                    </div>
                </div>
                <div class="marqueeL__group" aria-hidden="true">
                    <div className='flex items-center gap-5'>
                        <span>10th september </span>
                        <span className='w-4 h-4 lg:w-7 lg:h-7 bg-[#ac5dff] rounded-full'></span>
                    </div>
                    <div className='flex items-center gap-5'>
                        <span>10th september </span>
                        <span className='w-4 h-4 lg:w-7 lg:h-7 bg-[#ac5dff] rounded-full'></span>
                    </div>
                    <div className='flex items-center gap-5'>
                        <span>10th september </span>
                        <span className='w-4 h-4 lg:w-7 lg:h-7 bg-[#ac5dff] rounded-full'></span>
                    </div>
                    <div className='flex items-center gap-5'>
                        <span>10th september </span>
                        <span className='w-4 h-4 lg:w-7 lg:h-7 bg-[#ac5dff] rounded-full'></span>
                    </div>
                    <div className='flex items-center gap-5'>
                        <span>10th september </span>
                        <span className='w-4 h-4 lg:w-7 lg:h-7 bg-[#ac5dff] rounded-full'></span>
                    </div>
                    <div className='flex items-center gap-5'>
                        <span>10th september </span>
                        <span className='w-4 h-4 lg:w-7 lg:h-7 bg-[#ac5dff] rounded-full'></span>
                    </div>
                    <div className='flex items-center gap-5'>
                        <span>10th september </span>
                        <span className='w-4 h-4 lg:w-7 lg:h-7 bg-[#ac5dff] rounded-full'></span>
                    </div>
                    <div className='flex items-center gap-5'>
                        <span>10th september </span>
                        <span className='w-4 h-4 lg:w-7 lg:h-7 bg-[#ac5dff] rounded-full'></span>
                    </div>
                    <div className='flex items-center gap-5'>
                        <span>10th september </span>
                        <span className='w-4 h-4 lg:w-7 lg:h-7 bg-[#ac5dff] rounded-full'></span>
                    </div>
                    <div className='flex items-center gap-5'>
                        <span>10th september </span>
                        <span className='w-4 h-4 lg:w-7 lg:h-7 bg-[#ac5dff] rounded-full'></span>
                    </div>
                    <div className='flex items-center gap-5'>
                        <span>10th september </span>
                        <span className='w-4 h-4 lg:w-7 lg:h-7 bg-[#ac5dff] rounded-full'></span>
                    </div>
                </div>
            </div>
            <div class="marqueeR ">
                <div class="marqueeR__group">
                    <div className='flex items-center gap-5'>
                        <span>kochi</span>
                        <span className='w-2 h-2 bg-white rounded-full'></span>
                    </div>
                    <div className='flex items-center gap-5'>
                        <span>kaloor international stadium</span>
                        <span className='w-2 h-2 bg-white rounded-full'></span>
                    </div>
                    <div className='flex items-center gap-5'>
                        <span>kochi</span>
                        <span className='w-2 h-2 bg-white rounded-full'></span>
                    </div>
                    <div className='flex items-center gap-5'>
                        <span>kaloor international stadium</span>
                        <span className='w-2 h-2 bg-white rounded-full'></span>
                    </div>
                    <div className='flex items-center gap-5'>
                        <span>kochi</span>
                        <span className='w-2 h-2 bg-white rounded-full'></span>
                    </div>
                    <div className='flex items-center gap-5'>
                        <span>kaloor international stadium</span>
                        <span className='w-2 h-2 bg-white rounded-full'></span>
                    </div>
                    <div className='flex items-center gap-5'>
                        <span>kochi</span>
                        <span className='w-2 h-2 bg-white rounded-full'></span>
                    </div>
                    <div className='flex items-center gap-5'>
                        <span>kaloor international stadium</span>
                        <span className='w-2 h-2 bg-white rounded-full'></span>
                    </div>
                    <div className='flex items-center gap-5'>
                        <span>kochi</span>
                        <span className='w-2 h-2 bg-white rounded-full'></span>
                    </div>
                    <div className='flex items-center gap-5'>
                        <span>kaloor international stadium</span>
                        <span className='w-2 h-2 bg-white rounded-full'></span>
                    </div>
                </div>
                <div class="marqueeR__group" aria-hidden="true">
                    <div className='flex items-center gap-5'>
                        <span>kochi</span>
                        <span className='w-2 h-2 bg-white rounded-full'></span>
                    </div>
                    <div className='flex items-center gap-5'>
                        <span>kaloor international stadium</span>
                        <span className='w-2 h-2 bg-white rounded-full'></span>
                    </div>
                    <div className='flex items-center gap-5'>
                        <span>kochi</span>
                        <span className='w-2 h-2 bg-white rounded-full'></span>
                    </div>
                    <div className='flex items-center gap-5'>
                        <span>kaloor international stadium</span>
                        <span className='w-2 h-2 bg-white rounded-full'></span>
                    </div>
                    <div className='flex items-center gap-5'>
                        <span>kochi</span>
                        <span className='w-2 h-2 bg-white rounded-full'></span>
                    </div>
                    <div className='flex items-center gap-5'>
                        <span>kaloor international stadium</span>
                        <span className='w-2 h-2 bg-white rounded-full'></span>
                    </div>
                    <div className='flex items-center gap-5'>
                        <span>kochi</span>
                        <span className='w-2 h-2 bg-white rounded-full'></span>
                    </div>
                    <div className='flex items-center gap-5'>
                        <span>kaloor international stadium</span>
                        <span className='w-2 h-2 bg-white rounded-full'></span>
                    </div>
                    <div className='flex items-center gap-5'>
                        <span>kochi</span>
                        <span className='w-2 h-2 bg-white rounded-full'></span>
                    </div>
                    <div className='flex items-center gap-5'>
                        <span>kaloor international stadium</span>
                        <span className='w-2 h-2 bg-white rounded-full'></span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default InfintiMarquee
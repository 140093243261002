import React from 'react'
import image3 from '../assets/images/gallary/17.jpg'
import image4 from '../assets/images/gallary/4.jpg'
import image5 from '../assets/images/gallary/2.jpg'
import { MetaDecorator } from '../Component/MetaDecorator';
import hubburasool from '../assets/svg/hubburasool.svg'

function About() {

    const components = [
        {
            img: image5,
            title: "Prophet's praise audience",
            para: 'A two-hour long Sharaful Anam Mawlid led by eminent Sadatims, scholars, and Mutallims'
        },
        {
            img: image3,
            title: 'Spiritual fulfillment prayer',
            para: 'A special prayer led by prominent Sayyids and scholars to seek blessings and guidance for our community. scholars to seek blessings and guidance for our community.'
        },
        {
            img: image4,
            title: 'Madh Rasool Sermon ',
            para: "The Madh Rasool discourse is central to strengthening faith and deepening the spiritual connection with the teachings of Prophet Muhammad (ﷺ)."
        },
    ]
    return (
        <div className='relative flex flex-col w-full h-full gap-10 px-6 py-14 lg:py-20 lg:gap-20 lg:px-36'>
            <MetaDecorator
                title='About - Hubbu Rasool Conference'
                description=' The Ernakulam Hubburasool Conference is a significant religious gathering that unites thousands of devotees in the spiritual heart of Kerala. This esteemed event, led by the respected Kalthara Usthad, focuses on spreading the message of love, peace, and unity through the teachings of Islam. The conference is renowned for its enlightening sessions and the presence of eminent scholars. A highlight of the event is the profound and honest speech delivered by Sheikh Abubakar Ahmed, whose words inspire and/.. gassetsde the attendees towards a path of righteousness and communal harmony. The conference not only serves as a platform for spiritual growth but also strengthens the bonds within the community, making it a pivotal event in the religious calendar.'
                imageUrl={hubburasool}
            />
            <div className='text-center space-y-7 md:space-y-10 '>
                <h1 className='text-[#050404] text-3xl md:text-5xl font-extrabold uppercase  '>who are we?</h1>
                <p className='lg:px-16 lg:text-lg'>The Ernakulam Hubburasool Conference is an annual event that has become a cornerstone of spiritual and communal life in Kerala. Drawing thousands of participants from across the region, this gathering is a testament to the deep-rooted Islamic traditions that flourish in the state. The conference is meticulously organized, bringing together scholars, devotees, and community leaders for a series of enlightening sessions, discussions, and spiritual activities. <br /> <br />At the helm of this revered event is the esteemed Kalthara Usthad, a leader known for his profound knowledge, humility, and dedication to the propagation of Islamic teachings. His leadership ensures that the conference remains a beacon of spiritual enlightenment, guiding attendees through the complexities of modern life with the wisdom of centuries-old traditions.<br /> <br />One of the most anticipated moments of the conference is the speech by Sheikh Abubakar Ahmed, a respected Islamic scholar whose words resonate deeply with the audience. His speeches are known for their honesty, clarity, and unwavering commitment to truth, addressing both the spiritual and social challenges faced by the community. Sheikh Abubakar Ahmed's message often emphasizes the importance of unity, compassion, and adherence to the core values of Islam, inspiring listeners to lead lives of integrity and service.<br /> <br /> The Ernakulam Hubburasool Conference is more than just an event; it is a spiritual revival that reaffirms the faith and strengthens the bonds of brotherhood among Muslims in Kerala. Through this conference, the legacy of Islamic scholarship and community service continues to thrive, making it a pivotal and transformative experience for all who attend.</p>
            </div>
            {/* <div className='flex flex-col w-full gap-8 py-10 lg:gap-16'> */}
            {/* <h1 className='text-[#050404] text-3xl md:text-5xl text-center font-extrabold uppercase  '>components</h1> */}
            <div className='grid items-center w-full grid-cols-1 gap-1 overflow-hidden rounded-3xl lg:grid-cols-3'>
                {components.map((com, index) => (
                    <div key={index} className='relative overflow-hidden group'>
                        <img className='object-cover h-80 lg:h-[500px]' src={com.img} alt="" />
                        <div className=' absolute p-6 lg:p-8 inset-0 flex items-center justify-center text-center transition-all duration-500 ease-in-out from-[#000000d3] to-[#00000081] bg-gradient-to-b '>
                            <h1 className='group-hover:hidden text-3xl lg:text-5xl font-bold text-[#58c3f4] uppercase'>{com.title}</h1>
                            <p className='hidden text-white lg:text-lg group-hover:flex '>{com.para}</p>
                        </div>
                    </div>
                ))}
            </div>
            {/* </div> */}
            {/* <div className='flex flex-col items-start h-full gap-5 md:flex-row lg:gap-20'>
                <div className='h-72 lg:h-96 bg-tranparent border-8 border-[#b0e0f7bd] rounded-t-full overflow-hidden relative w-full md:w-1/3 '>
                    <img className='absolute -bottom-2' src={image} alt="" />
                </div>
                <div className='flex flex-col h-full gap-2 text-center md:w-2/3 md:text-start '>
                    <h2 className='text-2xl font-bold lg:text-3xl'>Abdul Khadar Madani Kalthara</h2>
                    <div className='lg:text-lg'>
                        <p className=''>President, Samastha Ernakulam</p>
                        <p className=''>Chairman, Hubbu Rasool </p>
                    </div>
                    <p className='pt-3 lg:text-lg'><b>Kalthara Usthad</b> is a highly respected Islamic scholar and the esteemed president of the Ernakulam district Samastha. He is deeply committed to both religious education and community service, which is evident through his leadership at <b>Mambaul Uloom Academy</b>  in Karimukal, Ernakulam. This academy serves as a beacon of Islamic learning, nurturing students in the teachings of Islam and the principles of Prophet Muhammad (PBUH). Kalthara Usthad is also well-known for his extensive social work throughout the Ernakulam district, where he actively engages in initiatives that uplift the community and spread the message of Islam. His teachings, grounded in the sayings of Prophet Muhammad, emphasize compassion, justice, and the importance of serving others, making him a beloved and influential figure in the region.</p>
                </div>
            </div> */}
        </div>
    )
}

export default About
import React from 'react';
import ab from '../assets/images/speakers/ab.png'
import kusthad from '../assets/images/speakers/kusthad.png'
import drmah from '../assets/images/speakers/drmah.png'
import sayyidkhaleel from '../assets/images/speakers/sayyidkhaleel.png'

export const SpeackersData = [
    {
        id: 1,
        name: (<p>Sayyid Ali Bafaqi Thangal</p>),
        designation: "Samastha vice president",
        image: ab,
        styles: "bg-[#3B44F6]",
        content: "His Eminence Sheikh Abubakr Ahmad is a name that Muslim India calls with uninterrupted excitement. Sheikh Abubakr Ahmad's unending efforts to serve the community, mainly through unequal commitment and unique determination, kicked off a new era in India's secular land. As the Grand Mufti of India and a renowned scholar representing India in international arenas, he is a panacea to several complicated religious issues and intricacies the Muslim community faces."
    },
    {
        id: 2,
        name: (<p>Sayyid Ibraheem Khaleel Al Bukhari</p>),
        designation: "Secretary, Samastha Kerala",
        image: sayyidkhaleel,
        styles: "bg-[#3EC70B]",
        content: ''
    },
    {
        id: 3,
        name: (<p>Abdul Khadar Madani Kalthara</p>),
        designation: "President, Samastha Ernakulam",
        image: kusthad,
        styles: "bg-[#3B44F6]",
        content: ''
    },
    {
        id: 4,
        name: (<p>Dr Abdul Hakeem Azhari</p>),
        designation: "Gen Secretary, SYS Kerala ",
        image: drmah,
        styles: "bg-[#3EC70B]",
        content: ''
    },
];
import React, { useEffect, useState } from "react";
import { ref as dbRef, onValue } from "firebase/database";
import { db } from "../utils/firebase";
import { Link } from "react-router-dom";
import { MetaDecorator } from '../Component/MetaDecorator';
import hubburasool from '../assets/svg/hubburasool.svg'
import { MdArrowOutward } from "react-icons/md";

const News = () => {
    const [newses, setNewses] = useState([]);

    useEffect(() => {
        const newsRef = dbRef(db, "news");
        onValue(newsRef, (snapshot) => {
            const newsData = snapshot.val();
            const newsArray = [];
            for (let id in newsData) {
                newsArray.push({ id, ...newsData[id] });
            }
            newsArray.sort((a, b) => new Date(b.publishDate) - new Date(a.publishDate));
            setNewses(newsArray);
        });
    }, []);

    return (
        <div className=" flex flex-col min-h-screen bg-gradient-to-b from-[#]  gap-10 lg:px-36 py-20 lg:py-32 px-6">
            <MetaDecorator
                title='News - Hubbu Rasool Conference'
                description=' The Ernakulam Hubburasool Conference is a significant religious gathering that unites thousands of devotees in the spiritual heart of Kerala. This esteemed event, led by the respected Kalthara Usthad, focuses on spreading the message of love, peace, and unity through the teachings of Islam. The conference is renowned for its enlightening sessions and the presence of eminent scholars. A highlight of the event is the profound and honest speech delivered by Sheikh Abubakar Ahmed, whose words inspire and/.. gassetsde the attendees towards a path of righteousness and communal harmony. The conference not only serves as a platform for spiritual growth but also strengthens the bonds within the community, making it a pivotal event in the religious calendar.'
                imageUrl={hubburasool}
            />
            <div className="grid grid-cols-1 gap-5 xl:gap-10 md:grid-cols-2 lg:grid-cols-3">
                {newses.map((news) => (
                    <div className="flex flex-col items-start justify-between h-full gap-3 overflow-hidden font-manjari">
                        <img
                            src={news.imageUrl}
                            alt={news.title}
                            className="object-cover w-full h-56 rounded-t-3xl md:h-64"
                        />
                        <h2 className="text-2xl font-normal leading-7 md:text-3xl ">{news.title}</h2>
                        <Link className='flex items-center gap-3 p-1 pl-4 text-white bg-black rounded-full md:text-lg' to={`/News/${news.id}`} onClick={() => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })} >
                            <span className="pt-1">Continue Read</span>
                            <MdArrowOutward className='p-1 text-2xl text-black bg-white rounded-full md:text-3xl' />
                        </Link>
                    </div>
                ))}
            </div>
        </div >
    );
};

export default News;
